@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    @apply font-Nunito bg-gray-300 dark:bg-slate-900 text-[#3E3E3E] dark:text-white
}

.burger {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  transition: 300ms;
  border-radius: 10px;
}
.burger input[type="checkbox"] {
  appearance: none;
  transition: 300ms;
  cursor: pointer;
}

.burger::before,
.burger::after {
  content: "";
  transition: 300ms;
  transform-origin: center center;
}

.burger::before {
  transform: translateY(8px);
}
.burger::after {
  transform: translateY(-8px);
}
.burger .line,
.burger::before,
.burger::after {
  width: 25px;
  height: 2.1px;
  display: block;
  background-color: black;
  border-radius: 5px;
  position: absolute;
  outline: none;
}

.burger .line:checked {
  width: 0;
  transition-delay: 100ms;
}
.burger:has(.line:checked)::before {
  animation: animation1 400ms ease-out 0s 1 both;
  background-color: #F90607;
}
.burger:has(.line:checked)::after {
  animation: animation2 400ms ease-out 0s 1 both;
  background-color: #F90607;
}

.burger:hover {
  background: rgb(209 213 219);
  border-radius: 50%;
}


.burger:active {
  scale: 0.95;
}
@keyframes animation1 {
  0% {
    transform: translateY(8px) rotate(0deg);
  }
  50% {
    transform: translateY(0px) rotate(0deg);
  }
  100% {
    transform: translateY(0px) rotate(45deg);
  }
}
@keyframes animation2 {
  0% {
    transform: translateY(-8px) rotate(0deg);
  }
  50% {
    transform: translateY(0px) rotate(0deg);
  }
  100% {
    transform: translateY(0px) rotate(-45deg);
  }
}

.loader {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #F90607 0%, white 50% 100%);
  border-radius: 50%;
  filter: blur(1px);
  animation: spin 1s linear infinite;
}

.loader span {
  content: "";
  position: absolute;
  width: 95%;
  height: 95%;
  background: linear-gradient(0deg, #F90607 0%, white 50% 100%);
  border-radius: 50%;
  filter: blur(4px);
  animation: spin 1s linear infinite;
}

.loader::after {
  content: "";
  position: absolute;
  width: 85%;
  height: 85%;
  filter: blur(1px);
  background-color: white;
  border-radius: 50%;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.image {
  filter: drop-shadow(-40px 10px 25px rgba(0, 0, 0, 0.61));
}